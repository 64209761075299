import React from "react"

import styles from "./DownloadIcon.module.css"

type Props = {
    onClick: () => void,
    className: string,
    style: {}
}

class PenColorIcon extends React.Component<Props> {
    public static defaultProps: Props = {
        onClick: () => { },
        className: "",
        style: {}
    }
    constructor(props: Props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    render() {
        return <svg onClick={this.onClick} className={`${styles.download} ${this.props.className}`} style={this.props.style} version="1.2" baseProfile="tiny" width="210mm" height="210mm" viewBox="0 0 21000 21000" preserveAspectRatio="xMidYMid" fillRule="evenodd" strokeWidth="28.222" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
            <g visibility="visible">
                <g>
                    <path fill="rgb(102,102,102)" stroke="none" d="M 8755,1768 L 8755,10856 7080,10856 10500,14586 13920,10856 12245,10856 12245,1768 8755,1768 Z" />
                    <rect fill="none" stroke="none" x="7080" y="1768" width="6841" height="12819" />
                    <rect fill="none" stroke="none" x="7081" y="1769" width="6840" height="12818" />
                </g>
                <g>
                    <path fill="rgb(102,102,102)" stroke="none" d="M 1391,11764 L 4451,11764 4451,17168 16551,17168 16551,11764 19611,11764 19611,17168 19611,19655 19611,19703 1390,19703 1390,17168 1391,17168 1391,11764 Z" />
                    <rect fill="none" stroke="none" x="1390" y="11764" width="18222" height="7940" />
                </g>
            </g>
        </svg>
    }
    onClick() {
        this.props.onClick();
    }
}
export default PenColorIcon;