
import Peer, { PeerConstructorOption } from 'skyway-js';
export default class Remote {
    private constructor() {
    }
    public static createPeer(id?: string): any {
        var param = {
            key: 'b82a323d-6b2b-40e7-a41f-499e99297866',
            debug: 0
        } as PeerConstructorOption;
        if (id) {
            return new Peer(id, param)
        } else {
            return new Peer(param);
        }
    }
}