import React from "react"

import Drawer from "../components/Drawer"
import ShareSwitch from "../components/ShareSwitch"
import Image from "../models/Image";
import Remote from "../models/Remote";
import DrawData from "../models/DrawData";
import DrawEvent from "../models/DrawEvent";
import ContextIF from "../models/ContextIF"

import { NavigateFunction, useNavigate, Params, useParams } from 'react-router-dom';

import { showToast, ToastComponent, ToastContainer, ToastType } from "../components/Toast";

import styles from "./Images.module.css";
import BackArrow from "../components/BackArrow";
import Storage from "../utils/Storage";
import { UserContext } from "../context/UserContext";

type Props = {
    navigation: NavigateFunction
    params: Params;
};
type State = {
    imageId: string,
    windowSize: { width: number, height: number }
    image?: Image;
}
class ImagePage extends React.Component<Props, State> {
    drawer!: Drawer;
    shareSwitch!: ShareSwitch;
    sharing = false;
    static contextType = UserContext;
    constructor(props: Props) {
        super(props);
        this.state = {
            imageId: "",
            windowSize: { width: 1000, height: 200 }
        };
        this.windowSizeChangeListener = this.windowSizeChangeListener.bind(this);
        this.beforeUnmount = this.beforeUnmount.bind(this);
        this.backToList = this.backToList.bind(this);
        this.toggleShare = this.toggleShare.bind(this);
        this.executeRedo = this.executeRedo.bind(this);
        this.executeUndo = this.executeUndo.bind(this);
        this.onTwoTouch = this.onTwoTouch.bind(this);
        this.onThreeTouch = this.onThreeTouch.bind(this);
        this.onFourTouch = this.onFourTouch.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.keyupListener = this.keyupListener.bind(this);
        this.keydownListener = this.keydownListener.bind(this);
        this.openShare = this.openShare.bind(this);
        this.closeShare = this.closeShare.bind(this);
        this.sendToPeer = this.sendToPeer.bind(this);
        this.onDrawStart = this.onDrawStart.bind(this);
        this.onDraw = this.onDraw.bind(this);
        this.onDrawEnd = this.onDrawEnd.bind(this);
        (window as any).draw = (func: any) => {
            const context = new ContextIF();
            func(context);
            context.layers.forEach((l, targetLayerIndex) => {
                l.drawDatas.forEach(drawData => {
                    this.drawer.draw(drawData, targetLayerIndex);
                    this.sendToPeer(new DrawEvent({ drawData, type: DrawEvent.TYPE_DRAW, targetLayerIndex }));
                });
            });
            this.onDrawEnd(this.drawer.getImage());
        }
    }
    render() {
        if (typeof window === 'undefined') {
            return <></>
        }
        if (!this.state.image) {
            return <></>
        }
        return <div>
            <BackArrow className={styles.backArrow} style={this.backArrowStyle} isSlimStyle={this.noMenu} onClick={this.backToList} />
            <ShareSwitch ref={(s: ShareSwitch) => this.shareSwitch = s} className={styles.share} style={this.buttonStyle} onClick={this.toggleShare}></ShareSwitch>
            <Drawer ref={(d: Drawer) => this.drawer = d} width={this.state.windowSize.width} height={this.state.windowSize.height} image={this.state.image} onDrawStart={this.onDrawStart} onDraw={this.onDraw} onDrawEnd={this.onDrawEnd} onTwoTouch={this.onTwoTouch} onThreeTouch={this.onThreeTouch} onFourTouch={this.onFourTouch} />
            <ToastContainer />
        </div>;
    }

    windowSizeChangeListener() {
        const imageId = this.props.params.imageId as string;
        this.setState({
            imageId,
            windowSize: { width: window.innerWidth, height: window.innerHeight }
        });
    }

    executeUndo() {
        this.drawer.undo((targetLayerIndex, strokeId) => this.sendToPeer(new DrawEvent({ type: DrawEvent.TYPE_UNDO, strokeId, targetLayerIndex })));
        this.didUndo = true;
    }
    executeRedo() {
        this.drawer.redo((targetLayerIndex, strokeId) => this.sendToPeer(new DrawEvent({ type: DrawEvent.TYPE_REDO, strokeId, targetLayerIndex })));
    }
    noMenu = false;
    buttonStyle = {};
    drawerPaletStyle = {};
    backArrowStyle = {};
    didUndo = false;
    keydownListener(e: KeyboardEvent) {
        if (e.code === "Space") {
            e.preventDefault();
            this.hideMenu();
        } else if ((e.code === "KeyY" && (e.ctrlKey || e.metaKey)) || (e.code === "KeyZ" && (e.ctrlKey || e.metaKey) && e.shiftKey)) {
            e.preventDefault();
            this.executeRedo()
        } else if (e.code === "KeyZ" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            this.executeUndo();
        }
    }
    keyupListener(e: KeyboardEvent) {
        if (e.code === "Space") {
            e.preventDefault();
            this.showMenu();
        }
    }
    showMenu() {
        this.buttonStyle = {};
        this.backArrowStyle = {};
        this.noMenu = false;
        this.drawer.showPalet();
        this.setState({});

    }
    hideMenu() {
        this.buttonStyle = { right: "-300px" };
        this.backArrowStyle = { left: "-100px" };
        this.noMenu = true;
        this.drawer.hidePalet();
        this.setState({});
    }
    onTwoTouch() {
        this.executeUndo();
    }
    onThreeTouch() {
        this.executeRedo();
    }
    onFourTouch() {
        if (this.noMenu) {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    }
    async componentDidMount() {
        this.windowSizeChangeListener();
        window.addEventListener('resize', this.windowSizeChangeListener);
        window.addEventListener('keyup', this.keyupListener);
        window.addEventListener('keydown', this.keydownListener);
        window.addEventListener("beforeunload", this.beforeUnmount);
        this.setState({ ...this.state, image: await Storage.loadImage(this.context.uid, this.props.params.imageId!) });
    }
    componentDidUpdate() {
        if (this.state.imageId !== this.props.params.imageId) {
            this.windowSizeChangeListener();
        }
    }
    beforeUnmount() {
        if (this.sharing) {
            return "いっしょにモードがおわりますが、いいですか？";
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.windowSizeChangeListener)
        window.removeEventListener('keyup', this.keyupListener);
        window.removeEventListener('keydown', this.keydownListener);
        window.removeEventListener("beforeunload", this.beforeUnmount);
        if (this.sharing) {
            this.closeShare();
        }
    }
    onDrawStart() {
        this.drawLayerIndex = -1;
        this.currentDrawDatas = []
    }
    drawLayerIndex: number = -1;
    currentDrawDatas: DrawData[] = [];

    async onDraw(drawData: DrawData, targetLayerIndex: number) {
        this.sendToPeer(new DrawEvent({ drawData, type: DrawEvent.TYPE_DRAW, targetLayerIndex }));
        this.drawLayerIndex = targetLayerIndex;
        this.currentDrawDatas.push(drawData);
    }
    async onDrawEnd(image: Image) {
        image.key = this.props.params.imageId as string;
        if (this.didUndo) {
            image.layers.forEach(l => {
                l.drawDatas = l.drawDatas.filter(d => !d.deletedAt);
            })
            this.didUndo = false;
            await Storage.save(this.context.uid, image);
        } else {
            await Storage.appendsDrawData(this.context.uid, this.state.image!, this.drawLayerIndex, this.currentDrawDatas);
            this.drawLayerIndex = -1;
            this.currentDrawDatas = [];
        }
    }
    dataConnections: any[] = [];
    sendToPeer(drawEvent: DrawEvent) {
        this.dataConnections.forEach(d => d.send(drawEvent.toString()));
    }
    backToList() {
        let back = true;
        if (this.sharing) {
            back = window.confirm("いっしょにモードがおわりますが、いいですか？")
        }
        if (back) {
            this.props.navigation("/list");
        }
    }
    toggleShare() {
        if (this.sharing) {
            const stopShare = window.confirm("いっしょにモードをおわっていいですか？");
            if (stopShare) {
                this.closeShare();
            }
        } else {
            this.openShare();
        }
    }
    closeShare() {
        this.peer.destroy();
        this.sharing = false;
        this.shareSwitch.toShare(this.sharing);
        this.dataConnections = [];
        this.setState({});
    }
    peerId?: string;
    peer: any;
    doSharing = false;
    openShare() {
        if (this.doSharing) {
            return;
        }
        this.doSharing = true;
        this.peer = Remote.createPeer(this.peerId);
        this.peer.once('open', (id: string) => {
            this.peerId = id;
            const url = window.location.protocol + "//" + window.location.host + "/shared/" + id;
            const copyUrl = (e: React.MouseEvent) => {
                e.preventDefault();
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(url);
                }
                toast.update("コピーされました。", { autoClose: 5000, type: ToastType.INFO });
            }
            this.sharing = true;
            this.shareSwitch.toShare(this.sharing);
            this.doSharing = false;
            this.setState({});
            const toast = showToast(<ToastComponent onClick={copyUrl}>共有URLをコピー📋</ToastComponent>, { autoClose: false });
        });

        this.peer.on('connection', (dataConnection: any) => {
            dataConnection.on('open', async () => {
                this.dataConnections.push(dataConnection);
                this.shareSwitch.setConnectNumber(this.dataConnections.length);
                const event = new DrawEvent({ image: this.state.image, type: DrawEvent.TYPE_COPY });
                dataConnection.send(event.toString());
            });


            dataConnection.on('data', (data: string) => {
                const event = DrawEvent.fromJSON(data);
                if (event.type === DrawEvent.TYPE_DRAW) {
                    this.drawer.draw(event.drawData, event.targetLayerIndex);
                    this.sendToPeer((new DrawEvent({ drawData: event.drawData, type: DrawEvent.TYPE_DRAW, targetLayerIndex: event.targetLayerIndex })));
                } else if (event.type === DrawEvent.TYPE_DRAW_END) {
                    this.onDrawEnd(this.drawer.getImage());
                }
            });
            dataConnection.once('close', () => {
                this.dataConnections = this.dataConnections.filter(d => d !== dataConnection);
                if (this.shareSwitch) {
                    this.shareSwitch.setConnectNumber(this.dataConnections.length);
                }
            });
        });

        this.peer.on('error', (e: Error) => showToast("いっしょにモードでエラー " + e));
    }
}
// eslint-disable-next-line
export default function (props: {}) {
    const navigation = useNavigate();
    const params = useParams();
    return <ImagePage {...props} navigation={navigation} params={params} />;
}