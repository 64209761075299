
export default function write(kigouarray, ueketa, sitaketa, _mondaisuu) {
    return (ctx) => {
        let mondaisuu = _mondaisuu || 6
        let width = 3840;
        let height = 1500;//2160;
        let sanMarginLeft = 100;
        let marginLeft = 100;
        let marginBetweenNumberAndLine = 20;
        let wordBoxWidth = width / 2;
        let wordBoxHeight = height / (mondaisuu / 2);
        let fontSize = 100;
        let fontMarginLeft = 20;
        let lineHeight = 8;
        const rand = (notcontainsZero) => {
            if (notcontainsZero) {
                return (Math.floor(Math.random() * 8) + 1);
            }
            return (Math.floor(Math.random() * 9));
        }
        const randArray = (length) => {
            const returnArray = [];
            returnArray.push(rand(true));
            for (let i = 1; i < length; i++) {
                returnArray.push(rand());
            }
            return returnArray;
        }
        const validateMinus = (array1, array2) => {
            const a1 = array1.reduce((pre, cur, i) => pre + cur * (10 ** (array1.length - 1 - i)), 0);
            const a2 = array2.reduce((pre, cur, i) => pre + cur * (10 ** (array2.length - 1 - i)), 0);
            return a1 - a2 >= 0;
        }
        ctx.fillStyle = "rgb(255,255,255)"
        ctx.fillRect(0, 0, width, width);
        ctx.fillStyle = "rgb(0,0,0)"
        const writeFont = (x, y) => {
            const san = kigouarray[Math.floor(Math.random() * kigouarray.length)];
            for (var nums = [randArray(ueketa), randArray(sitaketa)]; san === "-" && validateMinus(nums[0], nums[1]); nums = [randArray(ueketa), randArray(sitaketa)]) {
            }
            nums[0].forEach((n, i) => {
                ctx.fillText(n + "", sanMarginLeft + x + (fontSize + fontMarginLeft) * i, y);
            })
            ctx.fillText(san, x, y + fontSize);
            nums[1].forEach((n, i) => {
                ctx.fillText(n + "", sanMarginLeft + x + (fontSize + fontMarginLeft) * (i + ueketa - sitaketa), y + fontSize);
            })
            ctx.fillRect(x, y + fontSize + marginBetweenNumberAndLine, (ueketa + 1) * ((fontSize + fontMarginLeft)) - fontMarginLeft * 2, lineHeight)
        }
        for (var i = 0; i < mondaisuu / 2; i++) {
            writeFont(marginLeft + 0, fontSize + wordBoxHeight * i);
            writeFont(marginLeft + wordBoxWidth, fontSize + wordBoxHeight * i);
        }
    }
}