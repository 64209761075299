export default function write(kigouarray) {
    return (ctx) => {
        let width = 3840;
        let height = 1500;//2160;
        let marginLeft = 100;
        let wordBoxWidth = width / 2;
        let wordBoxHeight = height / 5;
        let fontSize = 100;
        let fontMarginLeft = 120;
        let fontMarginHeight = 20;
        let lineWidth = 100;
        let lineHeight = 8;
        let bunsuuLeftPadding = 180;
        const rand = () => {
            return (Math.floor(Math.random() * 8) + 1) + "";
        }
        ctx.fillStyle = "rgb(255,255,255)"
        ctx.fillRect(0, 0, width, width);
        ctx.fillStyle = "rgb(0,0,0)"
        const writeFont = (width, height) => {

            const san = kigouarray[Math.floor(Math.random() * kigouarray.length)];
            for (var nums = [rand(), rand(), rand(), rand()]; san === "-" && ((nums[0] * nums[3]) - (nums[2] * nums[1])) < 0; nums = [rand(), rand(), rand(), rand()]) {
            }
            ctx.fillText(nums[0], width + fontMarginLeft, height + fontSize + fontMarginHeight);
            ctx.fillRect(width + fontSize, height + fontSize + fontMarginHeight * 2, lineWidth, lineHeight)
            ctx.fillText(nums[1], width + fontMarginLeft, height + (fontSize + fontMarginHeight) * 2);

            ctx.fillText(san, width + fontMarginLeft + bunsuuLeftPadding, height + (fontSize + fontMarginHeight) * 1.5);

            ctx.fillText(nums[2], bunsuuLeftPadding * 2 + width + fontMarginLeft, height + fontSize + fontMarginHeight);
            ctx.fillRect(bunsuuLeftPadding * 2 + width + fontSize, height + fontSize + fontMarginHeight * 2, lineWidth, lineHeight)
            ctx.fillText(nums[3], bunsuuLeftPadding * 2 + width + fontMarginLeft, height + (fontSize + fontMarginHeight) * 2);

            ctx.fillText("=", width + fontMarginLeft + bunsuuLeftPadding * 3, height + (fontSize + fontMarginHeight) * 1.5);

        }
        for (var i = 0; i < 5; i++) {
            writeFont(marginLeft + 0, wordBoxHeight * i);
            writeFont(marginLeft + wordBoxWidth, wordBoxHeight * i);
        }
    }
}