import React from "react"

import styles from "./BackArrow.module.css"

type Props = {
    onClick: () => void,
    style: {},
    className?: string,
    isSlimStyle: boolean
}

export default class BackArrow extends React.Component<Props> {
    public static defaultProps: Props = {
        onClick: () => { },
        style: {},
        className: "",
        isSlimStyle: false
    }
    constructor(props: Props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    render() {
        return <div className={`${styles.base} ${this.props.className}`} style={this.props.style} onClick={this.onClick}>
            <div className={`${styles.arrow} ${this.props.isSlimStyle ? styles.slimStile : styles.upper}`}></div>
            <div className={`${styles.arrow} ${this.props.isSlimStyle ? styles.slimStile : styles.lower}`}></div>
        </div>
    }
    onClick() {
        this.props.onClick();
    }
}