import React from "react"

import PenColorIcon from "./PenColorIcon";
import EraserIcon from "./EraserIcon";
import styles from "./Palet.module.css";

import { RangeStepInput } from 'react-range-step-input';

type Props = {
    onColorChange: (c: string) => void,
    onPenSizeChange: (c: number) => void,
    penSize: number;
    style: {},
    className: string
}

export default class Palet extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onPenSizeChange = this.onPenSizeChange.bind(this);
    }
    penSize = 5;
    render() {
        return <div className={styles.cover}>
            <div style={this.props.style} className={`${this.props.className} ${styles.container}`}>
                <PenColorIcon color="rgb(0,0,0)" className={styles.pen} onChange={this.props.onColorChange}></PenColorIcon>
                <PenColorIcon color="rgb(255,0,0)" className={styles.pen} onChange={this.props.onColorChange}></PenColorIcon>
                <PenColorIcon color="rgb(0,255,0)" className={styles.pen} onChange={this.props.onColorChange}></PenColorIcon>
                <PenColorIcon color="rgb(0,0,255)" className={styles.pen} onChange={this.props.onColorChange}></PenColorIcon>
                <EraserIcon className={styles.pen} onChange={this.props.onColorChange}></EraserIcon>
                <div className={styles.penSizeArea}>
                    <div>{this.penSize}</div>
                    <RangeStepInput
                        min={1} max={100}
                        value={this.penSize} step={1}
                        onChange={this.onPenSizeChange}
                    />
                </div>
            </div>
        </div>;
    }
    onPenSizeChange(e: any) {
        const penSize = parseInt(e.target.value);
        this.setState({ penSize });// toUpdate component
        this.penSize = penSize;
        this.props.onPenSizeChange(penSize);
    }
    clicked(e: React.MouseEvent<HTMLInputElement>) {
        e.preventDefault();
    }
}
