import React from "react"

import styles from "./ShareSwitch.module.css";

type Props = {
    onClick: () => void,
    className: string,
    isSharing: boolean,
    style: {}
}

export default class ShareSwitch extends React.Component<Props> {
    sharing!: boolean;
    connectNumber: number = 0;
    public static defaultProps: Props = {
        onClick: () => { },
        className: "",
        isSharing: false,
        style: {}
    }
    constructor(props: Props) {
        super(props);
        this.sharing = this.props.isSharing;
        this.onClick = this.onClick.bind(this);
    }
    render() {
        return <button className={`${this.props.className} ${styles.sharebutton}`} style={this.props.style} onClick={this.onClick}>
            <span>{this.sharing ? "いっしょにモード中" : "いっしょにモードにする"}</span>{this.sharing ? <span style={{ marginLeft: "20px" }}>{this.connectNums()}</span> : <></>}
        </button>
    }
    onClick() {
        this.props.onClick();
    }
    numArray: string[] = ["0️⃣", "1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣", "🔟️"];
    connectNums(): string {
        if (this.numArray.length <= this.connectNumber) {
            return "#️⃣";
        }
        return this.numArray[this.connectNumber];
    }
    setConnectNumber(num: number) {
        this.connectNumber = num;
        this.forceUpdate();
    }
    toShare(sharing: boolean) {
        this.sharing = sharing;
        this.forceUpdate();
    }
}