import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyApYolobhCNDkovavRu4mkw8ak89LBhO0s",
    authDomain: "mondaikun.firebaseapp.com",
    databaseURL: "https://mondaikun-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "mondaikun",
    storageBucket: "mondaikun.appspot.com",
    messagingSenderId: "806381547678",
    appId: "1:806381547678:web:69ace9aa15d9f120b2cb8f"
};

const app = initializeApp(firebaseConfig/*{
    apiKey: "hoge",
    authDomain: "fuga",
    projectId: "mondaikun",
}*/);
export default app;