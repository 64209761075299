import React from "react"

import styles from "./GotoImage.module.css"

type Props = {
    onClick: () => void,
    width: number,
    height: number,
}

export default class BackArrow extends React.Component<Props> {
    public static defaultProps: Props = {
        onClick: () => { },
        width: 20,
        height: 20,
    }
    render() {
        return <span onClick={this.props.onClick} className={styles.goto}>
            <svg version="1.2" baseProfile="tiny" width={this.props.width + "px"} height={this.props.height + "px"} viewBox="0 0 21000 21000" preserveAspectRatio="xMidYMid" fillRule="evenodd" strokeWidth="28.222" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
                <g visibility="visible">
                    <g>
                        <path fill="none" stroke="rgb(0,132,209)" id="Drawing_1_0" strokeWidth="500" strokeLinejoin="round" d="M 15032,11989 C 13763,12032 12308,11929 11060,11989 12213,12068 13425,11969 14578,12048 15883,12168 15485,14550 14578,14431 13499,14530 11969,14431 10947,14490 12195,14431 12422,14550 13444,14490 14645,14421 14918,16753 13613,16813 11571,16873 9359,17051 7373,16753 4989,16038 4838,15820 3457,15265 3457,13121 3419,10738 3400,8476 4762,7681 6257,7097 7485,6093 9135,4745 9245,1389 10720,2759 12194,4128 11174,5438 10493,6808 12820,6808 16564,6689 19458,6867 20253,6808 20991,8714 19458,9249 16735,9428 13897,9210 11117,9190 13879,9217 14023,9361 15611,9361 16291,9361 16735,11929 15032,11989 Z" />
                    </g>
                    <g>
                        <path fill="none" stroke="rgb(0,132,209)" id="Drawing_2_0" strokeWidth="500" strokeLinejoin="round" d="M 1142,7418 C 1009,7418 877,7550 877,7683 L 877,15969 C 877,16101 1009,16234 1142,16234 L 2202,16234 C 2334,16234 2467,16101 2467,15969 L 2467,7683 C 2467,7550 2334,7418 2202,7418 L 1142,7418 Z" />
                        <rect fill="none" stroke="none" x="878" y="7419" width="1590" height="8816" />
                    </g>
                </g>
            </svg>
        </span>
    }
}