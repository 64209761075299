import React from "react"
import { showToast, ToastComponent, ToastContainer } from "../components/Toast";

import Drawer from "../components/Drawer"
import Image from "../models/Image";
import Remote from "../models/Remote";
import DrawData from "../models/DrawData";
import DrawEvent from "../models/DrawEvent";

import { NavigateFunction, useNavigate, Params, useParams } from 'react-router-dom';
import Layer from "../models/Layer";

type Props = {
    navigation: NavigateFunction
    params: Params;
}
type State = {
    imageId: string,
    windowSize: { width: number, height: number }
}
class SharePage extends React.Component<Props, State> {
    drawer!: Drawer;
    constructor(props: Props) {
        super(props);
        this.state = {
            imageId: "",
            windowSize: { width: 1000, height: 200 }
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.windowSizeChangeListener = this.windowSizeChangeListener.bind(this);
        this.keyupListener = this.keyupListener.bind(this);
        this.keydownListener = this.keydownListener.bind(this);
        this.openShare = this.openShare.bind(this);
        this.sendToPeer = this.sendToPeer.bind(this);
        this.onDraw = this.onDraw.bind(this);
        this.onDrawEnd = this.onDrawEnd.bind(this);
    }
    render() {
        if (typeof window === 'undefined') {
            return <></>
        }
        const image = new Image({ key: this.state.imageId, layers: [new Layer()] });
        return <div>
            <Drawer ref={(d: Drawer) => this.drawer = d} width={this.state.windowSize.width} height={this.state.windowSize.height} image={image} onDraw={this.onDraw} onDrawEnd={this.onDrawEnd} />
            <ToastContainer />
        </div>;
    }
    windowSizeChangeListener() {
        this.setState({
            imageId: this.props.params.imageId as string,
            windowSize: { width: window.innerWidth, height: window.innerHeight }
        });
    }
    keydownListener(e: KeyboardEvent) {
        if (e.code === "Space") {
            e.preventDefault();
            this.drawer.hidePalet();
            this.setState({});
        }
    }
    keyupListener(e: KeyboardEvent) {
        if (e.code === "Space") {
            e.preventDefault();
            this.drawer.showPalet();
            this.setState({});
        }
    }
    componentDidMount() {
        this.windowSizeChangeListener();
        window.addEventListener('resize', this.windowSizeChangeListener);
        window.addEventListener('keyup', this.keyupListener);
        window.addEventListener('keydown', this.keydownListener);
        this.openShare();
    }
    componentDidUpdate() {
        if (this.state.imageId !== this.props.params.imageId) {
            this.windowSizeChangeListener();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.windowSizeChangeListener);
        window.removeEventListener('keyup', this.keyupListener);
        window.removeEventListener('keydown', this.keydownListener);
        if (this.dataConnection) {
            this.dataConnection.close(true, {
                once: true,
            })
        }
    }
    onDraw(drawData: DrawData, targetLayerIndex: number) {
        this.sendToPeer(new DrawEvent({ drawData, type: DrawEvent.TYPE_DRAW, targetLayerIndex }));
    }
    onDrawEnd(image: Image) {
        this.sendToPeer(new DrawEvent({ type: DrawEvent.TYPE_DRAW_END }));
    }
    dataConnection: any;
    sendToPeer(drawEvent: DrawEvent) {
        if (this.dataConnection) {
            try {
                this.dataConnection.send(drawEvent.toString());
            } catch (e) {
                showToast("共有に失敗しました。");
            }
        } else {
            showToast("未共有です。");
        }
    }
    openShare() {
        const remoteId = this.props.params.imageId as string;
        if (!remoteId) {
            setTimeout(() => this.openShare(), 10)
            return;
        }
        const peer = Remote.createPeer();

        peer.on('error', (e: Error) =>
            showToast(<ToastComponent onClick={() => this.dataConnection = peer.connect()} >切断されました。<br />再接続しますか？<br />{e.toString()}</ToastComponent >, { autoClose: 3000 })
        );
        peer.once('open', (id: string) => {
            this.dataConnection = peer.connect(remoteId);
            this.dataConnection.once('open', async () => {
                showToast("接続されました。");
            });

            this.dataConnection.on('data', (data: string) => {
                const event = DrawEvent.fromJSON(data);
                if (event.type === DrawEvent.TYPE_COPY) {
                    event.image.layers.forEach((l, layerIndex) => l.drawDatas.forEach(d => this.drawer.draw(d, layerIndex)));
                } else if (event.type === DrawEvent.TYPE_DRAW) {
                    this.drawer.draw(event.drawData, event.targetLayerIndex);
                } else if (event.type === DrawEvent.TYPE_DRAW_END) {
                } else if (event.type === DrawEvent.TYPE_UNDO) {
                    this.drawer.undoOf(event.targetLayerIndex, event.strokeId);
                } else if (event.type === DrawEvent.TYPE_REDO) {
                    this.drawer.redoOf(event.targetLayerIndex, event.strokeId);
                }
            });

            this.dataConnection.once('close', () => {
                showToast("切断されました。", { autoClose: false });
            });
        });
    }
}

// eslint-disable-next-line
export default function (props: {}) {
    const navigation = useNavigate();
    const params = useParams();
    return <SharePage {...props} navigation={navigation} params={params} />;
}