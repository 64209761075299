import DrawData from "./DrawData";
import Image from "./Image";
export default class DrawEvent {
    type!: string;
    drawData!: DrawData;
    targetLayerIndex!: number;
    image!: Image;
    sessionId!: string;
    strokeId!: string;
    createdAt!: number;
    constructor(src: Partial<DrawEvent>) {
        Object.assign(this, src);
        this.createdAt = new Date().getTime();
    }
    public static readonly TYPE_DRAW = "d";
    public static readonly TYPE_COPY = "c";
    public static readonly TYPE_DRAW_START = "s";
    public static readonly TYPE_DRAW_END = "e";
    public static readonly TYPE_UNDO = "undo";
    public static readonly TYPE_REDO = "redo";
    toString(): string {
        return JSON.stringify(this);
    }
    static fromJSON(json: string) {
        return JSON.parse(json) as DrawEvent;
    }
}