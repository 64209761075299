
import React from 'react';
import User from '../models/User';

import styles from './NameAndLogout.module.css';

type Props = {
    user: User;
    onClick: (event: React.MouseEvent) => void;
}
export default class NameAndLogout extends React.Component<Props> {
    render() {
        return <div className={styles.area}>
            <div>{this.props.user.displayName}</div>&nbsp;
            {// once logout. a little time cant login again.
            //<button onClick={this.props.onClick}>ログアウト</button>
            }
        </div>
    }
}