import React from "react"

import Colors from "../models/Colors";

import styles from "./ErasorIcon.module.css"

type Props = {
    onChange: (c: string) => void,
    className: string
}

export default class EraserIcon extends React.Component<Props> {
    public static defaultProps: Props = {
        onChange: () => { },
        className: "",
    }
    constructor(props: Props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    render() {
        return <svg onClick={this.onClick} className={`${styles.eraser} ${this.props.className}`} version="1.2" baseProfile="tiny" width="210mm" height="210mm" viewBox="0 0 21000 21000" preserveAspectRatio="xMidYMid" fillRule="evenodd" strokeWidth="28.222" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
            <g visibility="visible">
                <g>
                    <path fill="rgb(255,255,255)" stroke="none" d="M 15205,11748 C 15590,12128 16355,12124 16735,11739 L 19781,8658 C 20161,8274 20157,7508 19772,7128 L 14152,1573 C 13767,1193 13000,1197 12620,1582 L 9575,4663 C 9194,5048 9199,5813 9584,6194 L 15205,11748 Z" />
                    <path fill="none" stroke="rgb(128,128,128)" id="Drawing_1_0" strokeWidth="200" strokeLinejoin="round" d="M 15205,11748 C 15590,12128 16355,12124 16735,11739 L 19781,8658 C 20161,8274 20157,7508 19772,7128 L 14152,1573 C 13767,1193 13000,1197 12620,1582 L 9575,4663 C 9194,5048 9199,5813 9584,6194 L 15205,11748 Z" />
                    <rect fill="none" stroke="none" x="8815" y="813" width="11728" height="11697" />
                </g>
                <g>
                    <path fill="rgb(230,230,255)" stroke="none" d="M 13685,7666 L 17266,11204 8010,20570 849,13494 10105,4128 13685,7666 Z" />
                    <path fill="none" stroke="rgb(128,128,128)" id="Drawing_2_0" strokeWidth="200" strokeLinejoin="round" d="M 13685,7666 L 17266,11204 8010,20570 849,13494 10105,4128 13685,7666 Z" />
                </g>
            </g>
        </svg>
    }
    onClick() {
        this.props.onChange(Colors.ERASER);
    }
}