import React from "react"

import styles from "./Dialog.module.css"
type Props = {
    title: string;
    onCancel: () => void;
}
export default class Dialog extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
    }
    public static defaultProps: Props = {
        title: "",
        onCancel: () => { }
    }
    _isShowing: boolean = false;
    render() {
        return <>
            <div className={styles.cover} style={{ display: this._isShowing ? "block" : "none" }}></div >
            <div className={styles.dialog} style={{ display: this._isShowing ? "block" : "none" }}>
                {this.props.title ? <div className={styles.title}>{this.props.title}</div> : <></>}
                <div className={styles.body}>{this.props.children}</div>
                <div className={styles.footer}><span onClick={this.onCancel}>もどる</span></div>
            </div>
        </>
    }
    public onCancel() {
        this.hide();
        this.props.onCancel();
    }
    public isShowing() {
        return this._isShowing;
    }
    public show() {
        this._isShowing = true;
        this.forceUpdate();
    }
    public hide() {
        this._isShowing = false;
        this.forceUpdate();
    }
}