import Colors from "./Colors";
export default class DrawData {
    fromX!: number;
    fromY!: number;
    toX!: number;
    toY!: number;
    penColor!: string;
    brushSize!: number;
    type!: string;
    word!: string;
    font!: string;
    sessionId!: string;
    strokeId!: string;
    deletedAt!: number;
    createdAt: number;
    constructor(src?: Partial<DrawData>) {
        Object.assign(this, src);
        this.createdAt = new Date().getTime();
    }
    public static readonly TYPE_LINE = "l";
    public static readonly TYPE_FILL_TEXT = "t";
    public static readonly TYPE_STROKE_TEXT = "st";
    public static readonly TYPE_FILL_RECT = "r";
    public static readonly TYPE_STROKE_RECT = "sr";
    public static draw(context: CanvasRenderingContext2D, drawData: DrawData) {
        if (drawData.deletedAt) {
            return;
        }
        if (drawData.penColor === Colors.ERASER) {
            context.globalCompositeOperation = "destination-out";
        } else {
            context.globalCompositeOperation = "source-over";
            context.strokeStyle = drawData.penColor;
            context.fillStyle = drawData.penColor;
        }
        if (drawData.type === DrawData.TYPE_FILL_TEXT) {
            context.font = drawData.font || (drawData.brushSize) + "px sans-serif";
            context.fillText(drawData.word, drawData.fromX, drawData.fromY);
        } else if (drawData.type === DrawData.TYPE_STROKE_TEXT) {
            context.font = drawData.font || (drawData.brushSize) + "px sans-serif";
            context.strokeText(drawData.word, drawData.fromX, drawData.fromY);
        } else if (drawData.type === DrawData.TYPE_FILL_RECT) {
            context.fillRect(drawData.fromX, drawData.fromY, drawData.toX - drawData.fromX, drawData.toY - drawData.fromY);
        } else if (drawData.type === DrawData.TYPE_STROKE_RECT) {
            context.strokeRect(drawData.fromX, drawData.fromY, drawData.toX - drawData.fromX, drawData.toY - drawData.fromY);
        } else {
            DrawData.drawLine(context, drawData);
        }
    }
    private static drawLine(context: CanvasRenderingContext2D, drawData: DrawData) {
        context.lineJoin = "round";
        context.lineCap = "round";
        context.lineWidth = drawData.brushSize;
        context.beginPath();
        context.moveTo(drawData.fromX, drawData.fromY);
        context.lineTo(drawData.toX, drawData.toY);
        context.stroke();
        context.closePath();
    }
}