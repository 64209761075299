import React from "react"

import styles from "./PenColorIcon.module.css"

type Props = {
    color: string,
    currentColor: string | null,
    onChange: (c: string) => void,
    className: string
}

export default class PenColorIcon extends React.Component<Props> {
    public static defaultProps: Props = {
        color: "rgb(0,0,0)",
        currentColor: null,
        onChange: () => { },
        className: "",
    }
    constructor(props: Props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    render() {
        return <svg onClick={this.onClick} className={`${styles.svg} ${this.props.className}`} version="1.2" baseProfile="tiny" viewBox="0 0 7000 21000" preserveAspectRatio="xMidYMid" fillRule="evenodd" strokeWidth="28.222" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
            <g visibility="visible">
                <g>
                    <path className={`${this.props.className} ${styles.pen}`} fill={this.props.color} stroke={this.props.currentColor === this.props.color ? "rgb(197,0,11)" : "none"} d="M 290,17297 L 293,5320 323,5320 3514,356 6709,5219 6686,5219 6684,17151 6685,17184 6685,19871 6681,19934 6670,19996 6652,20058 6628,20119 6598,20177 6562,20233 6522,20287 6477,20336 6429,20383 6377,20424 6322,20461 6265,20493 6206,20518 6145,20538 6084,20550 6022,20555 952,20645 890,20642 828,20632 768,20615 709,20591 652,20561 597,20527 545,20487 497,20442 452,20394 412,20342 377,20287 347,20229 323,20170 305,20108 294,20046 290,19983 290,19143 290,17297 Z" />
                </g>
            </g>
        </svg>
    }
    onClick() {
        this.props.onChange(this.props.color);
    }
}