import ContextIF from "./ContextIF";

import seisuuEnvedded from "../scriptlet/number";
import bunsuuEnvedded from "../scriptlet/bunsuu";
import hissanEnvedded from "../scriptlet/hissan";
import Layer from "./Layer";
export default class MondaiGenerator {
    constructor(public title: string, private script: any) { }
    getTitle(): string {
        return this.title;
    }
    generateMondai(): Layer[] {
        const ctx = new ContextIF();
        this.script(ctx);
        return ctx.layers;
    }
}
export const EmbeddedMondais: MondaiGenerator[] = [
    new MondaiGenerator("せいすうのたしざん", seisuuEnvedded(["+"])),
    new MondaiGenerator("せいすうのひきざん", seisuuEnvedded(["-"])),
    new MondaiGenerator("せいすうのたしひき", seisuuEnvedded(["+", "-"])),
    new MondaiGenerator("せいすうのかけざん", seisuuEnvedded(["×"])),
    new MondaiGenerator("せいすうのわりざん", seisuuEnvedded(["÷"])),
    new MondaiGenerator("せいすうのかけわり", seisuuEnvedded(["×", "÷"])),
    new MondaiGenerator("ぶんすうのたしざん", bunsuuEnvedded(["+"])),
    new MondaiGenerator("ぶんすうのひきざん", bunsuuEnvedded(["-"])),
    new MondaiGenerator("ぶんすうのたしひき", bunsuuEnvedded(["+", "-"])),
    new MondaiGenerator("ぶんすうのかけざん", bunsuuEnvedded(["×"])),
    new MondaiGenerator("ぶんすうのわりざん", bunsuuEnvedded(["÷"])),
    new MondaiGenerator("ぶんすうのかけわり", bunsuuEnvedded(["×", "÷"])),
    new MondaiGenerator("たしざんのひっさん(3,2)", hissanEnvedded(["+"], 3, 2)),
    new MondaiGenerator("ひきざんのひっさん(3,2)", hissanEnvedded(["-"], 3, 2)),
    new MondaiGenerator("たしひきのひっさん(3,2)", hissanEnvedded(["+", "-"], 3, 2)),
    new MondaiGenerator("かけざんのひっさん(3,2)", hissanEnvedded(["×"], 3, 2, 4)),
    new MondaiGenerator("たしざんのひっさん(3,3)", hissanEnvedded(["+"], 3, 3)),
    new MondaiGenerator("ひきざんのひっさん(3,3)", hissanEnvedded(["-"], 3, 3)),
    new MondaiGenerator("たしひきのひっさん(3,3)", hissanEnvedded(["+", "-"], 3, 3)),
    new MondaiGenerator("かけざんのひっさん(3,3)", hissanEnvedded(["×"], 3, 3, 4)),
];