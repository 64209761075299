import { Auth as _Auth, GoogleAuthProvider, getAuth, signOut, signInWithRedirect, getRedirectResult, onAuthStateChanged, User } from "firebase/auth";

export default class Auth {
    provider: GoogleAuthProvider;
    auth: _Auth;
    constructor() {
        this.provider = new GoogleAuthProvider();
        // this.provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        this.provider.addScope('profile');
        this.provider.addScope('email');
        this.auth = getAuth(); //initializeAuth(firebase, { persistence: browserLocalPersistence, popupRedirectResolver: browserPopupRedirectResolver, });////getAuth(firebase);//
        // setPersistence(this.auth, browserLocalPersistence)
    }
    private static LOCALSTORAGE_KEY_SIGNINED = "LOCALSTORAGE_KEY_SIGNINED";
    public async signInToGoogle() {
        window.localStorage.setItem(Auth.LOCALSTORAGE_KEY_SIGNINED, "true");
        signInWithRedirect(this.auth, this.provider);
    }
    public async getUser() {
        if (window.localStorage.getItem(Auth.LOCALSTORAGE_KEY_SIGNINED) !== "true") {
            return null;
        }
        try {
            window.localStorage.removeItem(Auth.LOCALSTORAGE_KEY_SIGNINED);
            const result = await getRedirectResult(this.auth, this.provider);
            const user = result!.user;
            return user
        } catch (error: any) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("------------ error " + errorCode + ":" + errorMessage);
            /*
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            */
            return null;
        }

    }
    public async logout() {
        await signOut(this.auth)

    }
    public onLoginStatusChanged(callback: (user: User | null) => void) {
        /*const unsuscrive =*/ onAuthStateChanged(this.auth, (user) => {
        callback(user);
        //     unsuscrive();
    }, (e) => {
        console.log("------onAuthStateChanged--------" + e);
    }, () => {
        console.log("----onAuthStateChanged completed----------");

    });
    }
}