import React from "react"
import DrawData from "../models/DrawData"
import Image from "../models/Image"

import styles from "./MultiCanvas.module.css"

type Props = {
    image: Image,
    className: string,
    width: number
}

export default class MultiCanvas extends React.Component<Props> {
    canvases: HTMLCanvasElement[] = [];
    public static defaultProps: Props = {
        image: new Image(),
        className: "",
        width: 260
    };
    constructor(props: Props) {
        super(props);
        this.convertToImageDataUrl = this.convertToImageDataUrl.bind(this);
    }
    render() {
        const widthFitsHeight = this.calcHeightFromWidth();
        var canvases = this.props.image.layers.map((d, i) => {
            return <canvas width={Image.WIDTH} height={Image.HEIGHT} className={styles.innerBase} style={{ width: this.props.width + "px", height: widthFitsHeight + "px" }} key={i} ref={container => {
                if (container) {
                    this.canvases.push(container);
                }
            }} ></canvas>
        })
        return <div className={`${this.props.className} ${styles.base}`} style={{ width: this.props.width + "px", height: widthFitsHeight + "px" }} > {canvases}</div >;
    }
    calcHeightFromWidth() {
        return Image.HEIGHT * this.props.width / Image.WIDTH;
    }
    componentDidMount() {
        this.componentDidUpdate();
    }
    componentDidUpdate() {
        setTimeout(() => {
            this.props.image.layers.forEach((layer, i) => {
                layer.drawDatas.forEach(d => this.draw(i, d));
            });
        })
    }
    draw(canvasIndex: number, drawData: DrawData) {
        const context = this.canvases[canvasIndex].getContext("2d") as CanvasRenderingContext2D;
        DrawData.draw(context, drawData);
    }
    convertToImageDataUrl(): string {
        const canvasForCreateImage = document.createElement("canvas");
        canvasForCreateImage.width = Image.WIDTH
        canvasForCreateImage.height = Image.HEIGHT;
        var destCtx = canvasForCreateImage.getContext('2d') as CanvasRenderingContext2D;
        var canvasArrayForSort = this.canvases.concat();;
        canvasArrayForSort /*.sort((c, v) => {
            return i;//TODO sort by z-index
        })*/.forEach(canvas => {
            destCtx.drawImage(canvas, 0, 0);
        })
        return canvasForCreateImage.toDataURL("image/png");
    }
    repaint(canvasIndex: number) {
        const context = this.canvases[canvasIndex].getContext("2d");
        context?.clearRect(0, 0, this.canvases[canvasIndex].width, this.canvases[canvasIndex].height);
        this.props.image.layers[canvasIndex].drawDatas.forEach(d => this.draw(canvasIndex, d));
    }
}