import app from './FirebaseApp'
import {
    getFirestore,
    collection,
    setDoc,
    getDoc,
    doc,
    //  connectFirestoreEmulator,
    query,
    getDocs,
    updateDoc,
    arrayUnion,
    deleteDoc,
    DocumentData,
} from "firebase/firestore";
import Image from '../models/Image';
import DrawData from '../models/DrawData';

const db = getFirestore(app);
if (window.location.hostname === "localhost") {
    //    connectFirestoreEmulator(db, "localhost", 8000);
}
export default class Storage {
    public static async save(uid: string, image: Image) {
        const convertedImage: any = JSON.parse(JSON.stringify(image));
        const layers: any = {};
        convertedImage.layers.forEach((l: any, i: number) => {
            layers[i + ""] = l;
        });
        convertedImage.layers = layers;
        await setDoc(doc(db, 'users', uid, 'images', image.key), convertedImage);
    }
    public static async deleteImage(uid: string, image: Image) {
        await deleteDoc(doc(db, 'users', uid, 'images', image.key));
    }
    public static async appendsDrawData(uid: string, image: Image, layerIndex: number, drawDatas: DrawData[]) {
        // https://firebase.google.com/docs/firestore/manage-data/add-data
        const layerKey = `layers.${layerIndex}.drawDatas`;
        const pushObject: any = {};
        pushObject[layerKey] = arrayUnion(...JSON.parse(JSON.stringify(drawDatas)));
        await updateDoc(doc(db, 'users', uid, 'images', image.key), pushObject);
    }
    public static async loadImage(uid: string, imageKey: string) {
        const image = await (await getDoc(doc(db, 'users', uid, 'images', imageKey))).data()
        return Storage.convertImageFromStorage(image!) as Image;
    }
    public static async loadImages(uid: string): Promise<Image[]> {
        const imageCollection = collection(db!, 'users', uid, 'images');
        const q = query(imageCollection);
        const querySnapshot = await getDocs(q);
        const returnData: Image[] = []
        querySnapshot.forEach(async (d) => {
            const image = d.data();

            returnData.push(Storage.convertImageFromStorage(image) as Image);
        });
        return returnData;
    }
    private static convertImageFromStorage(image: DocumentData) {
        if (!image) {
            return null;
        }
        const layers: any = [];
        Object.keys(image.layers).forEach((i: any) => {
            layers[parseInt(i)] = image.layers[i];
        });
        image.layers = layers;
        return image;
    }
}