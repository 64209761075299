import React from "react"

import MondaiGenerator, { EmbeddedMondais } from "../models/MondaiGenerator";

import styles from "./MondaiSelect.module.css";

type Props = {
    onSelected: (m: MondaiGenerator) => void
}

export default class BackArrow extends React.Component<Props> {
    render() {
        const mondaiLi = EmbeddedMondais.map((m, i) => <li className={styles.mondai} key={i} onClick={() => this.props.onSelected(m)}>{m.getTitle()}</li>);
        return <ul>
            {mondaiLi}
        </ul>
    }
}