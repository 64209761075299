import React from "react";
import { useNavigate, NavigateFunction } from "react-router-dom"

import GotoImage from "../components/GoToImage"
import User from "../models/User";
import Auth from "../utils/Auth";

import styles from "./index.module.css";
type Props = {
    navigation: NavigateFunction;
    updateUser: (user: User) => void
}
class Index extends React.Component<Props> {
    loaded?: boolean;
    user?: User;
    indexBox!: HTMLDivElement;
    boxArea!: HTMLDivElement;
    boxStyles = [
        {}, {}, {}, {}
    ]
    constructor(props: Props) {
        super(props);
        this.scrollListener = this.scrollListener.bind(this);
    }
    render() {
        if (!this.loaded || this.user) {
            return <></>
        }
        return <div className={styles.indexBody} ref={(r: HTMLDivElement) => this.indexBox = r}>
            <section className={styles.boxArea} ref={(b: HTMLDivElement) => this.boxArea = b}>
                <div className={`${styles.box} ${styles.box0}`} style={this.boxStyles[0]}></div>
                <div className={`${styles.box} ${styles.box1}`} style={this.boxStyles[1]}></div>
                <div className={`${styles.box} ${styles.box2}`} style={this.boxStyles[2]}></div>
                <div className={`${styles.box} ${styles.box3}`} style={this.boxStyles[3]}></div>
            </section>
            <section className={styles.read}>
                <div>
                    いっしょに<br />おべんきょう
                </div>
            </section>
            <section className={styles.infoArea}>
                <div>
                    <li>ぺんで、かけます。たぶれっとでつかうといいよ。</li>
                    <li>みんなで、かけます。こたえあわせしてもらってね。</li>
                    <li>もんだい、でてきます。いっぱいれんしゅうしてね。</li>
                    <li>こたえあわせは、「いっしょに」から。わかるひとにしてもらってね。</li>
                </div>
            </section>
            <section className={styles.goToList}>
                <div className={styles.link}><span onClick={this.loginToGoogle}>Googleでログインして、さっそくつかう。</span><GotoImage onClick={this.loginToGoogle} width={40} height={40} /></div>
                <div className={styles.linkInfo}>（次のページで+をおしてね。）</div>
            </section>
            <div className={styles.flexGotoLink}>
                <GotoImage onClick={this.loginToGoogle} width={50} height={50} />
            </div>
        </div >
    }
    async componentDidMount() {
        await new Auth().getUser();//I dont know why this code needed.
        new Auth().onLoginStatusChanged((user) => {
            if (user) {
                this.user = user;
                this.props.updateUser(user);
                this.props.navigation("/list");
                return;
            }
            this.loaded = true;
            this.forceUpdate();
        })
        window.addEventListener('scroll', this.scrollListener);

    }
    break = false;
    scrollListener() {
        if (window.scrollY > this.boxArea.offsetHeight - 100 && !this.break) {
            this.break = true;
            this.boxStyles[0] = { left: "-66.7%" };
            this.boxStyles[1] = { top: "-33.4%", right: "-33.4%" };
            this.boxStyles[2] = { right: "-33.4%" };
            this.boxStyles[3] = { top: "100%", right: "-33.4%" };
            this.forceUpdate();
        } else if (window.scrollY < this.boxArea.offsetHeight - 100 && this.break) {
            this.break = false;
            this.boxStyles[0] = this.boxStyles[1] = this.boxStyles[2] = this.boxStyles[3] = {};
            this.forceUpdate();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
    }
    loginToGoogle() {
        new Auth().signInToGoogle();
    }
}
// eslint-disable-next-line
export default function (props: { updateUser: (user: User) => void }) {
    const navigation = useNavigate();
    return <Index {...props} navigation={navigation} updateUser={props.updateUser} />;
}