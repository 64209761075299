import React from "react"
import DrawData from "../models/DrawData"

import { v4 as uuid } from "uuid";

type Props = {
    width: number,
    height: number,
    onDrawStart: () => void,
    onDraw: (a: DrawData) => void,
    onDrawEnd: () => void,
    onTwoTouch: () => void,
    onThreeTouch: () => void,
    onFourTouch: () => void,
    className: string
    style: {}
}

class Pentab extends React.Component<Props> {
    public static defaultProps: Props = {
        width: 300,
        height: 150,
        onDrawStart: () => { },
        onDraw: (a) => { },
        onDrawEnd: () => { },
        onTwoTouch: () => { },
        onThreeTouch: () => { },
        onFourTouch: () => { },
        className: "",
        style: {}
    };
    drawing: boolean = false;
    currentStrokeId: string = "";
    originPosition: { x: number, y: number } = { x: 0, y: 0 };
    context: CanvasRenderingContext2D | null = null;
    ref = React.createRef<HTMLDivElement>();
    render() {
        return <div className={this.props.className} style={{ width: this.props.width + 'px', height: this.props.height + 'px', ...this.props.style }} ref={this.ref}></div >
    }
    getTouchOffsetOf(event: any, i: number) {
        const rect = event.target.getBoundingClientRect();
        const offsetX = event.touches[i].clientX - window.pageXOffset - rect.left;
        const offsetY = event.touches[i].clientY - window.pageYOffset - rect.top;
        return { x: offsetX, y: offsetY };
    }
    createDrawData(x: number, y: number): DrawData {
        return new DrawData({
            fromX: this.originPosition.x,
            fromY: this.originPosition.y,
            toX: x,
            toY: y,
            penColor: "",
            brushSize: 0,
            strokeId: this.currentStrokeId,
            type: DrawData.TYPE_LINE,
        });
    }
    componentDidMount() {
        const canvas = this.ref.current as HTMLDivElement;
        const move = (event: any) => {
            if (!this.drawing) return;
            event.preventDefault();
            const currentDrawDatas: DrawData[] = [];
            if (event.touches && event.touches[0]) {
                const offset = this.getTouchOffsetOf(event, 0);
                const newDrawData = this.createDrawData(offset.x, offset.y);
                currentDrawDatas.push(newDrawData);
                this.originPosition.x = newDrawData.toX;
                this.originPosition.y = newDrawData.toY;
            } else {
                var newDrawDataOther = this.createDrawData(event.offsetX, event.offsetY);
                currentDrawDatas.push(newDrawDataOther);
                this.originPosition.x = newDrawDataOther.toX;
                this.originPosition.y = newDrawDataOther.toY;
            }
            currentDrawDatas.forEach((drawData: DrawData) => {
                if (this.props.onDraw) {
                    this.props.onDraw(drawData);
                }
            });
        };
        const start = (e: any) => {
            if (e.touches) {
                if (e.touches.length === 2) {
                    this.props.onTwoTouch();
                    return;
                }
                if (e.touches.length === 3) {
                    this.props.onThreeTouch();
                    return;
                }
                if (e.touches.length === 4) {
                    this.props.onFourTouch();
                    return;
                }
                const offset = this.getTouchOffsetOf(e, 0);
                this.originPosition.x = offset.x;
                this.originPosition.y = offset.y;
            } else {
                this.originPosition.x = e.offsetX;
                this.originPosition.y = e.offsetY;
            }
            this.drawing = true;
            this.currentStrokeId = uuid();
        };
        const end = () => {
            if (this.drawing === false) {
                return;
            }
            this.drawing = false;
            if (this.props.onDrawEnd) {
                this.props.onDrawEnd();
            }
        };
        canvas.addEventListener("touchmove", move);
        canvas.addEventListener("touchstart", start);
        canvas.addEventListener("touchend", end);

        canvas.addEventListener("mousemove", move);
        canvas.addEventListener("mousedown", start);
        canvas.addEventListener("mouseup", end);
        canvas.addEventListener("mouseleave", end);
    }
}
export default Pentab;