import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from "./pages/index"
import List from "./pages/List"
import Images from "./pages/Images"
import Shared from "./pages/Shared"
import Logout from "./pages/Logout"
import RequestUser from "./pages/RequestUser"
import { UserContext } from './context/UserContext';

import User from "./models/User";

type State = {
  user: User | null;
}
export default class App extends React.Component<any, State>{
  constructor(props: any) {
    super(props);
    this.state = { user: null };
    this.updateUser = this.updateUser.bind(this);
  }
  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Index updateUser={this.updateUser} />} />
            <Route path="/list" element={<RequestUser><List /></RequestUser>} />
            <Route path="/images/:imageId" element={<RequestUser><Images /></RequestUser>} />
            <Route path="/shared/:imageId" element={<RequestUser><Shared /></RequestUser>} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    );
  }
  checkAuth(element: JSX.Element): JSX.Element {
    return element;
  }
  updateUser(user: User) {
    this.setState({ user });
  }
}
