import Layer from "./Layer";
export default class Image {
    key!: string;
    layers: Layer[] = [new Layer()];
    createdAt!: Date;
    public static readonly WIDTH = 3840;
    public static readonly HEIGHT = 3160;
    constructor(src?: Partial<Image>) {
        Object.assign(this, src);
        this.createdAt = new Date();
    }
}