import React from "react"
import { useNavigate, NavigateFunction } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import User from "../models/User";

type Props = {
    navigation: NavigateFunction
}

class Requestuser extends React.Component<Props> {
    user!: User;
    render() {
        return <UserContext.Consumer>
            {(user) => {
                if (!user) {
                    return <></>
                }
                this.user = user;
                return <>{this.props.children}</>
            }}
        </UserContext.Consumer>
    }
    componentDidMount() {
        if (!this.user) {
            setTimeout(() => {
                this.props.navigation("/");
            })
        }
    }
}

// eslint-disable-next-line
export default function (props: { children: React.ReactNode }) {
    const navigation = useNavigate();
    return <Requestuser {...props} navigation={navigation} />;
}