import DrawData from "./DrawData";
import Layer from "./Layer";

export default class ContextIF {
    layers: Layer[] = [new Layer()];
    private currentLayer = 0;
    public strokeStyle: string = "rgb(0,0,0)";
    public fillStyle: string = "rgb(0,0,0)";
    public lineWidth: number = 10;
    public font: string = "100px sans-serif";
    public fillText(word: string, x: number, y: number) {
        this.layers[this.currentLayer].drawDatas.push(new DrawData({
            type: DrawData.TYPE_FILL_TEXT,
            fromX: x,
            fromY: y,
            word: word,
            font: this.font,
            brushSize: 100,
            penColor: this.fillStyle
        }));
    }
    public strokeText(word: string, x: number, y: number) {
        this.layers[this.currentLayer].drawDatas.push(new DrawData({
            type: DrawData.TYPE_STROKE_TEXT,
            fromX: x,
            fromY: y,
            word: word,
            font: this.font,
            brushSize: 100,
            penColor: this.fillStyle
        }));
    }
    public fillRect(x: number, y: number, width: number, height: number) {
        this.layers[this.currentLayer].drawDatas.push(new DrawData({
            type: DrawData.TYPE_FILL_RECT,
            fromX: x,
            fromY: y,
            toX: x + width,
            toY: y + height,
            penColor: this.fillStyle
        }));
    }
    public strokeRect(x: number, y: number, width: number, height: number) {
        this.layers[this.currentLayer].drawDatas.push(new DrawData({
            type: DrawData.TYPE_STROKE_RECT,
            fromX: x,
            fromY: y,
            toX: x + width,
            toY: y + height,
            penColor: this.strokeStyle
        }));
    }
}