
export default function write(kigouarray) {
    return (ctx) => {
        let width = 3840;
        let height = 1500;//2160;
        let marginLeft = 100;
        let wordBoxWidth = width / 2;
        let wordBoxHeight = height / 5;
        let fontSize = 100;
        let fontMarginLeft = 20;
        const rand = () => {
            return (Math.floor(Math.random() * 8) + 1);
        }
        ctx.fillStyle = "rgb(255,255,255)"
        ctx.fillRect(0, 0, width, width);
        ctx.fillStyle = "rgb(0,0,0)"
        const writeFont = (x, y) => {
            const san = kigouarray[Math.floor(Math.random() * kigouarray.length)];
            for (var nums = [rand(), rand()]; san === "-" && nums[0] - nums[1] < 0; nums = [rand(), rand()]) {
            }
            ctx.fillText(nums[0] + "", x, y);
            ctx.fillText(san, x + fontSize + fontMarginLeft, y);
            ctx.fillText(nums[1] + "", x + (fontSize + fontMarginLeft) * 2, y);
            ctx.fillText("=", x + (fontSize + fontMarginLeft) * 3, y);
        }
        for (var i = 0; i < 5; i++) {
            writeFont(marginLeft + 0, fontSize + wordBoxHeight * i);
            writeFont(marginLeft + wordBoxWidth, fontSize + wordBoxHeight * i);
        }
    }
}