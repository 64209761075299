import React from "react"

import styles from "./PluseIcon.module.css"

type Props = {
    onClick: () => void,
    className: string
}

export class PluseIcon extends React.Component<Props> {
    public static defaultProps: Props = {
        onClick: () => { },
        className: "",
    }
    constructor(props: Props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    render() {
        return <div className={`${styles.base} ${this.props.className}`}><svg onClick={this.onClick} className={`${styles.pluse}`} version="1.2" baseProfile="tiny" width="210mm" height="210mm" viewBox="0 0 21000 21000" preserveAspectRatio="xMidYMid" fillRule="evenodd" strokeWidth="28.222" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
            <g visibility="visible">
                <g>
                    <path fill="rgb(102,102,102)" stroke="none" d="M 8395,552 L 12604,552 12604,8395 20448,8395 20448,12604 12604,12604 12604,20448 8395,20448 8395,12604 552,12604 552,8395 8395,8395 8395,552 8395,552 Z" />
                    <rect fill="none" stroke="none" x="552" y="552" width="19897" height="19897" />
                    <rect fill="none" stroke="none" x="553" y="553" width="19896" height="19896" />
                </g>
            </g>
        </svg>
        </div>
    }
    onClick() {
        this.props.onClick();
    }
}