import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom"
import Auth from "../utils/Auth";

type Props = {
    navigation: NavigateFunction;
}

class Logout extends React.Component<Props>{
    render() {
        return <></>
    }
    componentDidMount() {
        new Auth().onLoginStatusChanged(async (user) => {
            if (user) {
                await new Auth().logout();
            }
            this.props.navigation("/");
        })
    }
}
// eslint-disable-next-line
export default function (props: {}) {
    const navigation = useNavigate();
    return <Logout {...props} navigation={navigation} />;
}