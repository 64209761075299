
import React, { ReactElement, ReactText } from 'react';
import { ToastContainer as _ToastContainer, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DEFAULT_PARAM = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};
export default class Toast {
    id: ReactText;
    constructor(id: ReactText) {
        this.id = id;
    }
    close() {
        toast.dismiss(this.id);
    }
    update(message: string | ReactElement, param?: Partial<ToastOptions>) {
        toast.update(this.id, {
            render: message,
            ...Object.assign(DEFAULT_PARAM, param)
        });
    }
}
type ClickableToastComponentProps = {
    onClick: (event: React.MouseEvent) => void;
}
export class ToastComponent extends React.Component<ClickableToastComponentProps> {
    render() {
        return <div onClick={this.props.onClick}>{this.props.children}</div>
    }
}
export function showToast(message: string | ReactElement, param?: Partial<ToastOptions>) {
    return new Toast(toast(message, Object.assign(DEFAULT_PARAM, param) as ToastOptions));
};
export const ToastContainer = _ToastContainer;
export const ToastType = toast.TYPE;