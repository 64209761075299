import React from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom"
import MultiCanvas from "../components/MultiCanvas";

import { v4 as uuid } from "uuid";
import Image from "../models/Image";
import styles from "./List.module.css";
import { PluseIcon } from "../components/PluseIcon";
import Dialog from "../components/Dialog";
import MondaiSelect from "../components/MondaiSelect"
import MondaiGenerator from "../models/MondaiGenerator";
import Layer from "../models/Layer";
import { UserContext } from '../context/UserContext';
import Storage from "../utils/Storage";
import NameAndLogout from "../components/NameAndLogout";

type Props = {
    navigation: NavigateFunction
};

type State = {
    images: Image[];
}
function dictionarySort(a: Image, b: Image) {
    var keyA = a.key.toUpperCase();
    var keyB = b.key.toUpperCase();
    if (keyA < keyB) {
        return 1;
    }
    if (keyA > keyB) {
        return -1;
    }
    return 0;
}
class List extends React.Component<Props, State> {
    remoteId?: string;
    mondaiDialog!: Dialog;
    static contextType = UserContext;
    constructor(props: Props) {
        super(props);
        this.itemAppended = this.itemAppended.bind(this);
        this.onRemoteIdChanged = this.onRemoteIdChanged.bind(this);
        this.showMondaiDialog = this.showMondaiDialog.bind(this);
        this.mondaiSelected = this.mondaiSelected.bind(this);
        this.logout = this.logout.bind(this);
    }
    render() {
        let multiCanvases = this.state?.images.sort(dictionarySort).map((image, index) => {
            return <Link key={index} to={"/images/" + image.key}>
                <div className={styles.imageBox} ><MultiCanvas image={image} /></div>
            </Link>
        })
        return <div>
            <div>
                <UserContext.Consumer>
                    {(user) => {
                        return <>
                            <button className={styles.mondaiButton} onClick={this.showMondaiDialog}>もんだいをとく</button>
                            <PluseIcon className={styles.plusIcon} onClick={() => this.itemAppended()}></PluseIcon>
                            <NameAndLogout user={user!} onClick={this.logout} />
                            <Dialog ref={(d: Dialog) => this.mondaiDialog = d} title="もんだい">
                                <MondaiSelect onSelected={this.mondaiSelected} />
                            </Dialog>
                        </>
                    }}
                </UserContext.Consumer>
            </div>
            <div className={styles.listBase}>{multiCanvases && multiCanvases.length === 0 ? "↑もんだいをとくか、+をおしてください。" : multiCanvases}</div>
        </div>
    }
    async componentDidMount() {
        const images = await Storage.loadImages(this.context!.uid);
        this.setState({ images });
    }
    itemAppended(layers: Layer[] = [new Layer()]) {
        const id = new Date().getTime() + uuid();
        const image = new Image({ key: id, layers });
        Storage.save(this.context!.uid, image);
        this.props.navigation("/images/" + id);
    }
    onRemoteIdChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.remoteId = event.target.value;
    }
    showMondaiDialog() {
        this.mondaiDialog.show();
    }
    mondaiSelected(mondaiGenerator: MondaiGenerator) {
        this.mondaiDialog.hide();
        const layers = mondaiGenerator.generateMondai();
        this.itemAppended(layers);
    }
    async logout() {
        this.props.navigation("/logout");
    }
}

// eslint-disable-next-line
export default function (props: {}) {
    const navigation = useNavigate();
    return <List {...props} navigation={navigation} />;
}