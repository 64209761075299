import React from "react"
import { v4 as uuid } from "uuid";

import DrawData from "../models/DrawData";
import Image from "../models/Image";
import Layer from "../models/Layer";
import ArrayUtils from "../utils/ArrayUtils";

import MultiCanvas from "./MultiCanvas"
import Pentab from "./Pentab";
import Palet from "./Palet"
import DownloadIcon from "./DownloadIcon"

import styles from './Drawer.module.css';

type Props = {
    width: number,
    height: number,
    image: Image,
    onTwoTouch?: () => void,
    onThreeTouch?: () => void,
    onFourTouch?: () => void,
    onDrawStart: () => void,
    onDraw: (drawData: DrawData, currentLayerIndex: number) => void
    onDrawEnd: (currentData: Image) => void
}

export default class Drawer extends React.Component<Props> {
    multiCanvas!: MultiCanvas
    public static defaultProps: Props = {
        width: 1000,
        height: 500,
        image: new Image(),
        onTwoTouch: () => { },
        onThreeTouch: () => { },
        onFourTouch: () => { },
        onDrawStart: () => { },
        onDraw: () => { },
        onDrawEnd: () => { }
    }
    image: Image;
    sessionId: string;
    currentLayerIndex: number = 0;
    currentColor: string = "rgb(0,0,0)";
    currentBrushSize: number = 5;
    constructor(props: Props) {
        super(props)
        this.onTwoTouch = this.onTwoTouch.bind(this);
        this.onThreeTouch = this.onThreeTouch.bind(this);
        this.onFourTouch = this.onFourTouch.bind(this);
        this.onDraw = this.onDraw.bind(this);
        this.onDrawEnd = this.onDrawEnd.bind(this);
        this.onColorChange = this.onColorChange.bind(this);
        this.onPenSizeChange = this.onPenSizeChange.bind(this);
        this.downloadImage = this.downloadImage.bind(this);
        this.draw = this.draw.bind(this);
        this.image = this.props.image;
        this.sessionId = uuid();
    }
    render() {
        return <div>
            <div className={styles.base} style={{ width: this.props.width + "px", height: this.props.height + "px" }} >
                <MultiCanvas className={styles.innerBase} ref={(m: MultiCanvas) => this.multiCanvas = m} image={this.image} width={this.props.width} />
                <Pentab className={styles.innerBase} style={{ zIndex: 998 }} onDraw={this.onDraw} onDrawEnd={this.onDrawEnd} width={this.props.width} height={this.props.height} onTwoTouch={this.onTwoTouch} onThreeTouch={this.onThreeTouch} onFourTouch={this.onFourTouch} ></Pentab>
                <br style={{ clear: "both" }} />
                <Palet className={styles.palet} onColorChange={this.onColorChange} onPenSizeChange={this.onPenSizeChange} penSize={this.currentBrushSize} style={Object.assign({ zIndex: 999 }, this.paletStyle)} ></Palet>
                <DownloadIcon className={styles.palet} style={Object.assign({ left: "400px", zIndex: 9999, width: "60px", height: "60px", padding: "10px" }, this.paletStyle)} onClick={this.downloadImage} />
            </div>
        </div>;
    }
    onColorChange(color: string) {
        this.currentColor = color;
    }
    onPenSizeChange(brushSize: number) {
        this.currentBrushSize = brushSize;
    }
    onTwoTouch() {
        this.props.onTwoTouch!();
    }
    onThreeTouch() {
        this.props.onThreeTouch!();
    }
    onFourTouch() {
        this.props.onFourTouch!();
    }
    onDrawStart() {
        if (this.props.onDrawStart) {
            this.props.onDrawStart();
        }
    }
    paletStyle = {};
    hidePalet() {
        this.paletStyle = { bottom: "-100px" }
        this.forceUpdate();
    }
    showPalet() {
        this.paletStyle = {}
        this.forceUpdate();
    }
    onDraw(drawData: DrawData) {
        drawData.penColor = this.currentColor;
        drawData.brushSize = this.currentBrushSize;
        drawData.fromX = this.calcActulalPointOnImage(drawData.fromX);
        drawData.fromY = this.calcActulalPointOnImage(drawData.fromY);
        drawData.toX = this.calcActulalPointOnImage(drawData.toX);
        drawData.toY = this.calcActulalPointOnImage(drawData.toY);
        drawData.sessionId = this.sessionId;
        this.draw(drawData, this.currentLayerIndex)
        if (this.props.onDraw) {
            this.props.onDraw(drawData, this.currentLayerIndex);
        }
    }
    getImage(): Image {
        return this.image;
    }
    draw(drawData: DrawData, targetLayer: number) {
        if (!this.image.layers[targetLayer]) {
            this.image.layers[targetLayer] = new Layer();
        }
        this.image.layers[targetLayer].drawDatas.push(drawData);
        this.multiCanvas.draw(0, drawData);
    }
    calcActulalPointOnImage(srcPoint: number): number {
        return srcPoint * Image.WIDTH / this.props.width;
    }
    onDrawEnd() {
        if (this.props.onDrawEnd) {
            this.props.onDrawEnd(this.image);
        }
    }
    undo(callback: (targetLayerIndex: number, strokeId: string) => void) {
        this.isHasLast((targetDatas, targetLayerIndex, lastNotDeleteIndex, targetStrokeId) => {
            if (lastNotDeleteIndex === -1) {
                return;
            }
            const deleteTime = new Date().getTime();
            for (let i = lastNotDeleteIndex; i >= 0 && (targetDatas[i].strokeId === targetStrokeId); i--) {
                targetDatas[i].deletedAt = deleteTime;
            }
            this.multiCanvas.repaint(this.currentLayerIndex);
            callback(targetLayerIndex, targetStrokeId);
        }, d => !d.deletedAt);
    }
    undoOf(targetLayerIndex: number, strokeId: string) {
        const deleteTime = new Date().getTime();
        this.image.layers[targetLayerIndex].drawDatas.filter(d => d.strokeId === strokeId).forEach(d => d.deletedAt = deleteTime);
        this.multiCanvas.repaint(this.currentLayerIndex);
    }
    private isHasLast(func: (drawData: DrawData[], targetLayerIndex: number, lastIndex: number, targetStrokeId: string) => void, findLastFunc: (d: DrawData) => boolean) {
        const targetDatas = this.image.layers[this.currentLayerIndex].drawDatas;
        let lastIndex = ArrayUtils.findLastIndex(targetDatas, findLastFunc);
        if (lastIndex === -1) {
            func(targetDatas, this.currentLayerIndex, lastIndex, "");
            return;
        }
        const targetStrokeId = targetDatas[lastIndex].strokeId;
        func(targetDatas, this.currentLayerIndex, lastIndex, targetStrokeId);
    }
    redo(callback: (targetLayerIndex: number, strokeId: string) => void) {
        this.isHasLast((targetDatas, targetLayerIndex, lastDeleteIndex) => {
            if (targetDatas.length === lastDeleteIndex + 1) {
                return;
            }
            const firstDeleteIndex = lastDeleteIndex + 1;
            const targetStrokeId = targetDatas[firstDeleteIndex].strokeId;
            for (let i = firstDeleteIndex; i < targetDatas.length && (targetDatas[i].strokeId === targetStrokeId && targetDatas[i].deletedAt); i++) {
                (targetDatas[i] as any).deletedAt = undefined;
            }
            this.multiCanvas.repaint(this.currentLayerIndex);
            callback(targetLayerIndex, targetStrokeId);
        }, d => {
            return !d.deletedAt
        });
    }
    redoOf(targetLayerIndex: number, strokeId: string) {
        this.image.layers[targetLayerIndex].drawDatas.filter(d => d.strokeId === strokeId).forEach(d => (d as any).deletedAt = undefined);
        this.multiCanvas.repaint(this.currentLayerIndex);
    }
    downloadImage() {
        const dataUrl = this.multiCanvas.convertToImageDataUrl() as string;
        let link = document.createElement("a");
        link.target = "_blank"
        link.href = dataUrl;
        link.download = "image.png";
        link.click();
    }
}